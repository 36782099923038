<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4 9H7.10002C7.56329 6.71776 9.58104 5 12 5C14.419 5 16.4367 6.71776 16.9 9H28C28.5523 9 29 9.44772 29 10C29 10.5523 28.5523 11 28 11H16.9C16.4367 13.2822 14.419 15 12 15C9.58104 15 7.56329 13.2822 7.10002 11H4C3.44772 11 3 10.5523 3 10C3 9.44772 3.44772 9 4 9ZM12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7Z"
		></path>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4 21C3.44772 21 3 21.4477 3 22C3 22.5523 3.44772 23 4 23H15.1C15.5633 25.2822 17.581 27 20 27C22.419 27 24.4367 25.2822 24.9 23H28C28.5523 23 29 22.5523 29 22C29 21.4477 28.5523 21 28 21H24.9C24.4367 18.7178 22.419 17 20 17C17.581 17 15.5633 18.7178 15.1 21H4ZM23 22C23 20.3431 21.6569 19 20 19C18.3431 19 17 20.3431 17 22C17 23.6569 18.3431 25 20 25C21.6569 25 23 23.6569 23 22Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconFilter",
});
</script>
